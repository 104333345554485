<template lang="pug">
    #why-hydrate(v-esc-close="performAction")
      .modal-text
        p In order to properly collect your sample, please hold the device exactly as shown.
      .modal-footer
        button.button.action-btn(@click="performAction") OK
  </template>

<script>
export default {
  props: {},

  data() {
    return {}
  },

  computed: {},

  watch: {},

  methods: {
    performAction,
  },

  components: {},
}

/* Computed ---------------------------------------------------- */
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
async function performAction() {
  this.$modal.close('HoldFingerCompressor')
}
</script>
